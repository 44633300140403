
import { computed, defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'conBox',
  props: {
    title: {
      type: String,
      default: ''
    },
    slotH: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const isFold = ref(false)
    const handleFoldClick = () => {
      isFold.value = !isFold.value
    }
    const slotHeight = computed(() =>
      isFold.value
        ? 0
        : (props.slotH <= 10 ? props.slotH : 11.5) * 36 + 10 + 'px'
    )
    return {
      isFold,
      slotHeight,
      handleFoldClick
    }
  }
})
