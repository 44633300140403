
import { defineComponent, reactive, ref, watch } from 'vue'
import { IComObj } from '@/global/types'
import { isDowmload } from '@/utils/download'

export default defineComponent({
  props: {
    sortDisabled:{
      type: Boolean,
      default: false
    },
    sortData: {
      type: Array,
      default: () => {
        return []
      }
    },
    sortId: {
      type: String,
      default: '0'
    },
    checkAllFlag: {
      type: Boolean,
      default: false
    },
    checkedAmount: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any, { emit }) {
    const checkedArr = ref(props.checkedAmount)
    const innerAllFlag = ref(props.checkAllFlag)
    const handleCheckAll = (data: boolean) => {
      innerAllFlag.value = data
      emit('checked', data)
    }

    const cu = ['0'].includes(props.sortId) ? 0 : (['1', '2'].includes(props.sortId) ? 1 : 2)
    // 升序降序
    const sortState = reactive({
      id: props.sortId,
      current: cu,
      issueFlag: true, // 发布日期
      carryFlag: true, //实施日期
      similarityData: [] as IComObj[],
      issueData: [] as IComObj[],
      carryData: [] as IComObj[],
      getData: () => {
        const _this = sortState
        if (!props.sortData.length) return
        props.sortData.forEach((item: any) => {
          if (item.type == '审判日期') {
            _this.issueData = item.list
          } else if (item.type == '实施日期') {
            _this.carryData = item.list
          } else {
            _this.similarityData = item.list
          }
        })
      },
      setVal: (val: string) => {
        sortState.id = val
        const cu = ['0'].includes(val) ? 0 : (['1', '2'].includes(val) ? 1 : 2)
        sortState.current = cu
      },
      handleClickSimlar: () => {
        const _this = sortState
        _this.current = 0
        if (_this.id == _this.similarityData[0].id) return
        _this.id = _this.similarityData[0].id
        emit('changeSort', _this.id)
      },
      handleClickIssue: () => {
        const _this = sortState
        _this.issueFlag = _this.current == 1 ? !_this.issueFlag : true
        _this.current = 1
        _this.id = _this.issueFlag
          ? _this.issueData[0].id
          : _this.issueData[1].id
        emit('changeSort', _this.id)
      },
      handleClickCarry: () => {
        const _this = sortState
        _this.carryFlag = _this.current == 2 ? !_this.carryFlag : true
        _this.current = 2
        _this.id = _this.carryFlag
          ? _this.carryData[0].id
          : _this.carryData[1].id
        emit('changeSort', _this.id)
      }
    })
    sortState.getData()

    // 批量下载
    const dowmload = (isDocx?) => {
      if (!checkedArr.value.length) return
      if(isDocx) isDowmload(checkedArr.value,'docx')
      else isDowmload(checkedArr.value)
    }

    watch(
      () => props.checkedAmount,
      () => {
        checkedArr.value = props.checkedAmount
      }
    )

    watch(
      () => props.sortId,
      () => {
        sortState.setVal(props.sortId)
      }
    )

    watch(
      () => props.sortData,
      () => {
        sortState.getData()
      }
    )

    watch(
      () => props.checkAllFlag,
      () => {
        innerAllFlag.value = props.checkAllFlag
      }
    )

    return {
      handleCheckAll,
      sortState,
      dowmload,
      innerAllFlag
    }
  }
})
