import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "LeftPane" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree = _resolveComponent("tree")!
  const _component_con_box = _resolveComponent("con-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_con_box, {
      title: _ctx.leixing.title,
      slotH: 2
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.leixing,
          onChange: _ctx.leixing.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createVNode(_component_con_box, {
      title: _ctx.anyou.title,
      slotH: 5
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.anyou,
          onChange: _ctx.anyou.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createVNode(_component_con_box, {
      title: _ctx.shenlifayuan.title,
      slotH: 8
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.shenlifayuan,
          onChange: _ctx.shenlifayuan.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createVNode(_component_con_box, {
      title: _ctx.guanjianci.title,
      slotH: 8
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.guanjianci,
          onChange: _ctx.guanjianci.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createVNode(_component_con_box, {
      title: _ctx.fayuanjibie.title,
      slotH: 5
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.fayuanjibie,
          onChange: _ctx.fayuanjibie.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createVNode(_component_con_box, {
      title: _ctx.shenlichegnxu.title,
      slotH: 4
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.shenlichegnxu,
          onChange: _ctx.shenlichegnxu.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createVNode(_component_con_box, {
      title: _ctx.shenpannianfen.title,
      slotH: 8
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.shenpannianfen,
          onChange: _ctx.shenpannianfen.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"])
  ]))
}