/*
 * @Author: wangjie
 * @Date: 2022-07-13 16:01:16
 * @Description: 单个下载/批量下载
 */
import httpRequest, { fzzykRequest } from '@/service'
import { BASE_URL } from '@/service/request/config'
/**
 * @param lawId 数组为批量下载 字符串为单个下载
 * @returns
 */
export async function isDowmload(lawId: string | string[], type = 'pdf') {
  if(process.env.VUE_APP_REQUIRE_LOGIN == 1){
    // 校验是否登录
    await fzzykRequest.get({ url: 'systemLoginCheck', params: { path: '' } })
  }
  let itUrl = '' //拼接的链接
  let variable = {} //动态的params
  if (Array.isArray(lawId)) {
    itUrl = BASE_URL + `/common/download/realtime?ids=${lawId.join(',')}`
    variable = { checkedId: lawId }
  } else {
    itUrl = BASE_URL + `/common/download/realtime?ids=${lawId}`
    variable = {
      lawVersionId: lawId
    }
  }
  if(type == 'docx'){
    itUrl += '&isDocx=true'
  }
  dowmloadFuc(itUrl)
  // httpRequest
  //   .post({
  //     url: 'download',
  //     params: variable
  //   })
  //   .then(() => {
  //     dowmloadFuc(itUrl)
  //   })
  //   .catch((err) => {
  //     console.log(err)
  //   })
}

function dowmloadFuc(itUrl: string) {
  const a = document.createElement('a')
  a.style.display = 'none'
  a.style.height = '0'
  a.href = itUrl
  a.download = itUrl
  document.body.appendChild(a)
  a.click()
  setTimeout(() => {
    a.remove()
  }, 300000)
}
