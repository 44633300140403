import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c0182792"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "con-box" }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */),
    _createElementVNode("div", {
      class: _normalizeClass(['fold', { isFold: _ctx.isFold }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFoldClick && _ctx.handleFoldClick(...args)))
    }, _toDisplayString(_ctx.isFold ? '+' : '-'), 3 /* TEXT, CLASS */),
    _createElementVNode("div", {
      class: "slot-box",
      style: _normalizeStyle({ height: _ctx.slotHeight })
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4 /* STYLE */)
  ]))
}